import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageIntro, PageSection, TieredCTAGrid, QrComponent, StaticTooltip, RelatedContent, IconDefinitionList } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';
import styled from 'styled-components';

// SEO INFORMATION
const pageMeta = {
    title: `Patient Support Program for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `patient support program for keytruda`,
    description: `Health care professionals may share information with their eligible patients about the KEY+YOU Patient Support Program for KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/key-you-patient-support-program/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/key-you-patient-support-program/","@type":"MedicalWebPage","name":"The KEY+YOU Patient Support Program for KEYTRUDA® (pembrolizumab)","description":"Patients and caregivers can get additional support throughout treatment by visiting keytruda.com or calling 855⁠-⁠398⁠-⁠7832 and pressing 2 to speak with a representative. Support includes educational resources and information to connect you with organizations that may help address day-to-day needs. The KEY+YOU Patient Support Program does not take the place of talking to your doctor or health care team. If you have any questions that are specific to your medical condition or treatment, or you’re having side effects, please contact your doctor’s office right away. This program provides patients access to educational resources to support them throughout their treatment journey."}`
    ]
}

const jobCode = jobCodes[31].jobCode;

const LinkStyle = styled.a`
text-decoration: underline !important;
`;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/hcp-and-patient-resources/',
            label: 'HCP & Patient Resources'
        }
    },
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    }
];

const resourceData = [
    {
        iconColumn: {
            icon: '/static/images/badge-phone@2x.png',
            heading: '1-on-1 Support',
            alt: ''
        },
        contentMarkup: `<p>When a patient calls KEY+YOU, they will be connected with a representative who can help provide support during treatment. This enables patients to get answers to questions such as:</p>
                            <ul>
                                <li>How can I prepare for infusions?</li>
                                <li>How can I manage living with cancer?</li>
                                <li>What educational resources are available to support me?</li>   
                            </ul>`,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-bookmark@2x.png',
            heading: 'Guide to Getting Started',
            alt: ''
        },
        contentMarkup: `<p>This step-by-step digital guide to starting KEYTRUDA walks patients through the infusion process, helping them understand what to expect before, during, and after infusions. This guide will help answer these questions and more:</p>
                            <ul>
                                <li>What should I know before my first treatment?</li>
                                <li>What should I know about side effects?</li>
                                <li>What do I need to know about my treatment schedule?</li>                
                            </ul>`, 
    },
    {
        iconColumn: {
            icon: '/static/images/badge-clipboard.png',
            heading: 'Symptom Tracker',
            alt: ''
        },
        contentMarkup: `<p>The printable Symptom Tracker can help patients:</p>
                            <ul>
                                <li>Understand common symptoms of treatment</li>
                                <li>Learn how to track them</li>
                                <li>Feel more prepared to share what they are experiencing to their health care team</li> 
                            </ul>   
                        <p>The Symptom Tracker can be used at any point in a patient’s treatment.</p>
        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-info.png',
            heading: 'Additional Resources',
            alt: ''
        },
        contentMarkup: `<p>KEY+YOU offers additional patient educational resources to help with their treatment with KEYTRUDA, including:</p>
                            <ul>
                                <li>Guides for talking with family and friends</li>
                                <li>Guides for communicating with their health care team</li>
                                <li>Emotional support tools</li>
                                <li>Cancer care team guide</li>
                                <li>Connections to other organizations</li>
                            </ul>`,
    },
]


const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro" maxWidth={824}>
                    <PageHeader title="KEY+YOU Patient Support Program" />
                    <PageSection bgColor="cloud" title="The KEY+YOU Patient Support Program for KEYTRUDA® (pembrolizumab)" centerTitle={true}>
                        <PageIntro centered={true}>
                            <p>Patients and caregivers can get additional support throughout treatment by visiting <strong><LinkStyle href="https://www.keytruda.com" target='_blank'>keytruda.com</LinkStyle></strong> or calling <LinkStyle href="tel:8553987832">855&#8288;-&#8288;398&#8288;-&#8288;7832</LinkStyle> and pressing 2 to speak with a representative. Support includes educational resources and information to connect you with organizations that may help address day-to-day needs. The KEY+YOU Patient Support Program does not take the place of talking to your doctor or health care team. If you have any questions that are specific to your medical condition or treatment, or you’re having side effects, please contact your doctor’s office right away.  </p>
                            <p>This program provides patients access to educational resources to support them throughout their treatment journey. </p>
                        </PageIntro>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title="Resources Patients Can Access With KEY+YOU">
                        <IconDefinitionList data={resourceData} theme="light"/>
                    </PageSection>
                    <PageSection bgColor="cloud" title="Share Materials With Your Patients">

                    <StaticTooltip alt="" text="Show this QR code to your patients or their caregivers to scan using their phone’s camera or QR scanning app. The scanned QR code will enable them to tap a notification and open the desired page." />
                        <QrComponent 
                            title="Help educate your patients on their eligibility to participate in the KEY+YOU patient support program"
                            text="<p>Patients who have been prescribed KEYTRUDA for an approved indication can access additional support from KEY+YOU, the patient support program for KEYTRUDA. KEY+YOU helps patients by offering educational resources and support information throughout treatment.</p>
                                  <p>KEY+YOU provides patients access to educational resources to support them throughout their treatment journey.</p>
                                  <p>Phone support is available in both English and Spanish. </p>"
                            definitions="The KEY+YOU Patient Support Program is intended only for residents of the United States, its territories, and Puerto Rico."
                            image={() => <StaticImage 
                                    
                                src="../../assets/phone-mock-key-you-v-3.png" 
                                placeholder="blurred" 
                                alt=""/>}
                            QRimage={() => <StaticImage 
                                src="../../assets/qr-key-you-v-1@2x.png" 
                                placeholder="blurred" 
                                alt="QR Code to Sign Up for KEY+YOU Patient Support Program"/>}
                            qrDescription="Patients may visit <a href='https://www.keytruda.com' target='_blank'><strong>keytruda.com</strong></a> to learn more information"
                        />

                        <QrComponent 
                            title="Help Your Patient Add the Wallet Card for KEYTRUDA to Their Smartphone"
                            text="<p>At some point, patients and caregivers may need to receive medical care from professionals who are not part of their cancer care team, such as ER staff. The mobile wallet card is an easy way to let professionals know that the patient is currently taking KEYTRUDA.</p>
                            <p>After scanning this QR code, your patient should see the wallet card for KEYTRUDA in the same app that holds things like tickets and credit cards.</p>
                            <p>Available on iOS and Android.</p>"
                            image={() => <StaticImage 
                                    
                                src="../../assets/phone-mock-wallet@2x.png"
                                placeholder="blurred" 
                                alt=""/>}
                            QRimage={() => <StaticImage 
                                src="../../assets/QR-Code.png" 
                                alt="QR Code That Adds the iOS or Android Wallet Card Directly to Your Patient’s Phone"/>}
                            qrDescription="Eligible patients may scan the QR code to add the IOS or Android wallet card directly to their phone"
                        />
                    </PageSection>

                    
                </TemplateColumn>
                {/* End Main Column */}
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
